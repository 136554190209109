import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import Layout from './Layout'
import EventPage from '@pages/EventPage'
import LoginPage from '@pages/LoginPage'
// import OrderPage from '@pages/OrderPage'
import ProtectedSignInRoute from './ProtectedSignInRoute'
import ProtectedRoute from './ProtectedRoute'
import SuccessfulPaymentPage from '@pages/SuccessfulPaymentPage'
import AgentPage from '@pages/AgentPage'
import LayoutV2 from './LayoutV2'
import EventListPage from '@pages/EventListPage'
import EventDetailsPage from '@pages/EventDetailsPage'
import EventSuccessfulPaymentPage from '@pages/EventSuccessfulPaymentPage'
import EventPaymentScreen from '@pages/EventPaymentScreen'
import AgentEventListPage from '@pages/AgentEventListPage'
import AgentCreateOrderPage from '@pages/AgentCreateOrderPage'
import AgentProtectedRoute from './AgentProtectedRoute'
import VideoPlayerPage from '@pages/VideoPlayerPage'
import FirebaseLayout from './../FirebaseLayout'
import ProtectedRouteByAgent from './ProtectedRouteByAgent'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<FirebaseLayout />}>
      <Route index element={<Navigate to="/events" />} />
      <Route element={<Layout />}>
        {/* <Route path="ASAP-HealinginAfrica" element={<EventPage />} /> */}

        <Route element={<ProtectedSignInRoute />}>
          <Route path="login" element={<LoginPage />} />
        </Route>
        {/* <Route element={<ProtectedRoute />}>
          <Route path="successful-payment" element={<SuccessfulPaymentPage />} />
        </Route> */}
        <Route element={<ProtectedRoute />}>
          <Route path="events/:id/video/:videoId" element={<VideoPlayerPage />} />
        </Route>

        <Route path="agents" element={<AgentPage />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Route>

      <Route element={<LayoutV2 />}>
        <Route element={<ProtectedRouteByAgent />}>
          <Route path="events" element={<EventListPage />} />
          <Route path="events/:id" element={<EventDetailsPage />} />
          <Route element={<ProtectedRoute />}>
            <Route path="events/:id/payment" element={<EventPaymentScreen />} />
            <Route path="events/successful-payment" element={<EventSuccessfulPaymentPage />} />
            {/* <Route path="events/:id/video/:videoId" element={<VideoPlayerPage />} /> */}
          </Route>
        </Route>

        <Route element={<AgentProtectedRoute />}>
          <Route path="agent" element={<AgentEventListPage />} />
          <Route path="agent/create-order/:id" element={<AgentCreateOrderPage />} />
        </Route>
      </Route>
    </Route>
  )
)

export default router
