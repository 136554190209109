import React, { useEffect } from 'react'
import Header from './Header'
import { Outlet, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify'
import ReactGA from 'react-ga4'

const Layout = () => {
  // const location = useLocation()

  // useEffect(() => {
  //   ReactGA.send({ hitType: 'pageview', page: location.pathname })
  // }, [location])

  return (
    <>
      {/* <Helmet>
        <title>{location.pathname}</title>
        <meta property="og:title" content="The Rock" />

        <meta property="og:site_name" content="Site name" />
        <meta property="og:description" content="Description" />
        <meta property="og:image" content="Link to your logo" />
      </Helmet> */}

      <main id="main">
        <Header />
        <div className="main-page-layout">
          <Outlet />
          <ToastContainer limit={5} />
        </div>
      </main>
     
    </>
  )
}

export default Layout
