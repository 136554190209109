import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import ReactGA from 'react-ga4'
import SideBar from './SideBar'
import HeaderDesktop from './HeaderDesktop'
import HeaderV2 from './HeaderV2'

const LayoutV2 = () => {
  // const location = useLocation()

  // useEffect(() => {
  //   ReactGA.send({ hitType: 'pageview', page: location.pathname })
  // }, [location])

  return (
    <>
      <main id="main-v2">
        {/* <HeaderDesktop /> */}
        <div className="centered-page-layout">
          <div className="centered-page-container">
            <HeaderV2 />
            <div className="centered-page-content light-background">
              <Outlet />
            </div>

            <ToastContainer limit={5} />
          </div>
        </div>
      </main>
    </>
  )
}

export default LayoutV2
