import { useAppSelector } from '@hooks/reduxHooks'
import localStorageData from '@utils/localStorage'
import isEqual from 'lodash/isEqual'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRouteByAgent = () => {
  const token = localStorageData.getData('authData')
  const user = useAppSelector(state => state.user.user, isEqual)

  if (!token || token && user && user.userRole !== 'Agent') {
    return <Outlet />
  }
  if (token && user && user.userRole === 'Agent') {
    return <Navigate to="/agent" />
  }


  return null
}

export default ProtectedRouteByAgent
