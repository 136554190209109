import agentActionCreators from "@actionCreators/agentActionCreators"
import authActionCreators from "@actionCreators/authActionCreators"
import { LoadState } from "@customTypes/common"
import { ShortEvent } from "@customTypes/event"
import { Order } from "@customTypes/purchase"
import { createSlice } from "@reduxjs/toolkit"

type AgentSlice = {
  addNewAgentLoadState: LoadState

  eventList: Array<ShortEvent>
  getEventListForAgentLoadState: LoadState
  orderList: Array<Order>

  currentEvent: Event | null
}

const initialState: AgentSlice = {
  addNewAgentLoadState: {
    isLoading: false,
    isLoaded: false,
  },

  eventList: [],
  getEventListForAgentLoadState: {
    isLoading: false,
    isLoaded: false,
  },
  orderList: [],
  currentEvent: null,
}


export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(agentActionCreators.addNewAgent.pending, state => {
        state.addNewAgentLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(agentActionCreators.addNewAgent.fulfilled, state => {
        state.addNewAgentLoadState = {
          isLoading: false,
          isLoaded: true,
        }
      })
      .addCase(agentActionCreators.addNewAgent.rejected, state => {
        state.addNewAgentLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })

      .addCase(agentActionCreators.getEventListForAgent.pending, state => {
        state.getEventListForAgentLoadState = {
          isLoading: true,
          isLoaded: false,
        }
      })
      .addCase(agentActionCreators.getEventListForAgent.fulfilled, (state, action) => {
        state.getEventListForAgentLoadState = {
          isLoading: false,
          isLoaded: true,
        }
        state.eventList = action.payload.data
      })
      .addCase(agentActionCreators.getEventListForAgent.rejected, state => {
        state.getEventListForAgentLoadState = {
          isLoading: false,
          isLoaded: false,
        }
      })
      .addCase(agentActionCreators.getEventByIdForAgent.pending, (state) => {
      })
      .addCase(agentActionCreators.getEventByIdForAgent.fulfilled, (state, action) => {
        state.currentEvent = action.payload

      })
      .addCase(agentActionCreators.getEventByIdForAgent.rejected, state => {
      })

      .addCase(agentActionCreators.getOrderListForAgent.fulfilled, (state, action) => {
        state.orderList = action.payload.data
      })




      .addCase(authActionCreators.logOut.fulfilled, () => initialState)

  }
})

export default agentSlice.reducer