import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import eventActionCreators from '@actionCreators/eventActionCreators'
import purchaseActionCreators from '@actionCreators/purchaseActionCreators'
import AgentCreateOrderTableRow from '@components/agent/AgentCreateOrderTableRow'
import { useAppDispatch, useAppSelector } from '@hooks/reduxHooks'
import { clearCurrentEvent } from '@sliceActionCreators/eventSlice'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Loader from '@components/ui/Loader'
import { EProductType } from '@customTypes/event'
import agentActionCreators from '@actionCreators/agentActionCreators'

type ProductItem = {
  productId: number
  qty: number
  productTotal: number
}

const AgentCreateOrderTable = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const eventData = useAppSelector(state => state.agent.currentEvent)
  const buyViaAgentIsLoading = useAppSelector(
    state => state.purchase.buyViaAgentLoadState.isLoading
  )

  const [productList, setProductList] = useState<Array<ProductItem>>([])
  const [isIdVerifiedChecked, setIsIdVerifiedChecked] = useState(false)
  const [buyerEmailValue, setBuyerEmailValue] = useState('')
  const [buyerEmailError, setBuyerEmailError] = useState('')

  useEffect(() => {
    if (id) {
      dispatch(agentActionCreators.getEventByIdForAgent(id))
        .unwrap()
        .catch(err => {
          // console.log(err)
          // navigate('/')
        })
    } else {
      navigate('/')
    }

    return () => {
      dispatch(clearCurrentEvent())
    }
  }, [dispatch, id, navigate])

  const productTotal = useMemo(() => {
    const total = productList.reduce((acc, item) => {
      return acc + item.productTotal
    }, 0)

    return `${total.toFixed(2)}`
  }, [productList])

  const toParentComp = useCallback((productId: number, quantity: number, productTotal: number) => {
    setProductList(prev => {
      const prevArrayWithoutCurrentProductId = prev.filter(
        prevItem => prevItem.productId !== productId
      )

      return [...prevArrayWithoutCurrentProductId, { productId, qty: quantity, productTotal }]
    })
  }, [])

  const buy = useCallback(async () => {
    const filteredProductList = productList
      .filter(product => product.productTotal > 0)
      .map(product => ({ productId: product.productId, qty: product.qty }))

    const body = {
      paymentProviderId: 1,
      products: filteredProductList,
      paymentProviderOptions: {
        buyerInfo: { idVerified: isIdVerifiedChecked },
        recipientEmail: buyerEmailValue,
      },
    }
    if (filteredProductList.length > 0) {
      dispatch(purchaseActionCreators.buyViaAgent(body))
        .unwrap()
        .then(() => navigate('/agent?activeTab=orders'))
    }
  }, [buyerEmailValue, dispatch, isIdVerifiedChecked, navigate, productList])

  const onIdVerifiedCheckboxClick = useCallback(() => {
    setIsIdVerifiedChecked(prev => !prev)
  }, [])

  const checkBuyerEmailField = useCallback((value: string) => {
    const trimmedValue = value.trim()
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!trimmedValue) {
      setBuyerEmailError('Required field')
      return
    }

    if (!emailPattern.test(trimmedValue)) {
      setBuyerEmailError('Invalid email')
      return
    }

    setBuyerEmailError('')
  }, [])

  const onBuyerEmailFieldChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      checkBuyerEmailField(value)
      setBuyerEmailValue(value)
    },
    [checkBuyerEmailField]
  )

  return (
    <>
      {buyViaAgentIsLoading && <Loader className="spinner-fixed" />}
      {eventData?.products && eventData?.products.length > 0 && (
        <>
          <div className="table">
            <div className="table-header agent-create-order-grid-columns">
              <div className="table-cell"></div>
              <div className="table-cell">Title</div>
              <div className="table-cell">Short Description</div>
              <div className="table-cell">Price</div>
              <div className="table-cell">Quantity</div>
            </div>
            <div className="table-body table-body-agent-create-order">
              {eventData?.products.map(product => (
                <>
                  {product.type !== EProductType.Video ? (
                    <AgentCreateOrderTableRow
                      toParentComp={toParentComp}
                      key={product.id}
                      product={product}
                    />
                  ) : null}
                </>
              ))}
            </div>
          </div>
          <div
            style={{ padding: '24px 24px', gap: '20px' }}
            className="display-flex align-items-end flex-direction-column"
          >
            <div className="form-control">
              <label htmlFor="buyer-email">Buyer's email</label>
              <input
                id="buyer-email"
                className="form-input"
                onChange={onBuyerEmailFieldChange}
                value={buyerEmailValue}
                type="string"
                placeholder="Enter email"
                maxLength={64}
                autoComplete="on"
              />
              <div className="form-control-error">{buyerEmailError}</div>
            </div>
            <label className="checkbox">
              <input
                type="checkbox"
                onChange={onIdVerifiedCheckboxClick}
                checked={isIdVerifiedChecked}
              />
              <div className="checkbox__body">ID verified</div>
              <div className="checkbox__checkmark"></div>
            </label>
            <div style={{ fontSize: '18px', fontWeight: '600' }}>
              TOTAL: {eventData?.products[0].currency.sign}
              {productTotal}
            </div>
            <button
              className="button middle primary"
              onClick={buy}
              disabled={productTotal === '0.00' || Boolean(buyerEmailError)}
            >
              Create Order
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default AgentCreateOrderTable
